import store from '@/store';
import { redirectToHomeScreen } from '@/router/guards';
import { redirectToOnboardingBranching } from '@/router/routes/deletedroutes';
import { getAccountIdFromCookie } from '@/util';
import moment from 'moment';

export const accountSetupRouteNames = {
  WELCOME: 'onboarding-welcome',
  QUALIFICATIONS: 'onboarding-qualification',
  AGENCY_BRANCHING: 'onboarding-agency-branching',
  AGENCY_CONTACT: 'onboarding-agency-contact',
  AGENCY_CONTACT_DETAILS: 'onboarding-agency-contact-details',
  AI: 'onboarding-ai-feature',
  BRANCHING: 'onboarding-branching',
  AGENCY: 'onboarding-agency',
  AFFILIATE_CALLENDLY: 'onboarding-affiliate_calendly',
};

const checkOnboardingRoute = () => {
  const lastOnboardingRoute = store.state.account?.settings?.onboarding?.stage;
  if (lastOnboardingRoute) {
    return lastOnboardingRoute;
  }
  return false;
};

const deletedAccountSetupRoutes = [
  {
    regexp: /\d+\/onboarding\/(hear|interested-feature|(ecommerce\/(role|who-use)))(.*)?/,
    redirect: (to) => redirectToOnboardingBranching({ ...to.query }),
  },
  {
    regexp: /\d+\/onboarding\/ai-feature(.*)?/,
    redirect: (to) => ({
      name: accountSetupRouteNames.AI,
      params: { ...to.params, userId: to.params.userId },
      query: { ...to.query },
    }),
  },
  {
    regexp: /\d+\/onboarding\/campaigns\/new\/(.*)?/,
    redirect: (to) => {
      const query = { ...to.query };
      const templateType = (to.path.split('campaigns/new')[1] || '').split('?')[0];
      if (templateType) {
        query.types = JSON.stringify([templateType]);
      }
      return {
        name: 'templates',
        query,
      };
    },
  },
  {
    regexp: /\d+\/onboarding\/non-wizard\/agency(.*)?/,
    redirect: (to) => ({
      name: 'onboarding_non_wizard',
      params: { userId: to.params.userId, step: 'goals' },
      query: { account: 'agency' },
    }),
  },
  {
    regexp: /\d+\/onboarding\/smart-wizard-brandkit(.*)?/,
    redirect: (to) => ({
      name: 'onboarding_wizard',
      params: { userId: to.params.userId, step: 'goals' },
      query: { ...to.query },
    }),
  },
];

function isDeletedRoute(path) {
  return deletedAccountSetupRoutes.find(({ regexp }) => regexp.test(path));
}

const children = [
  {
    path: 'welcome',
    name: accountSetupRouteNames.WELCOME,
    component: () => import('@/views/Onboarding/pages/Welcome.vue'),
    meta: { permission: ['normal', 'agency'] },
  },
  {
    path: 'qualification',
    name: accountSetupRouteNames.QUALIFICATIONS,
    alias: ['/saas', '/blog', '/ecommerce', '/other', '/'],
    component: () => import('@/views/Onboarding/pages/Qualification.vue'),
    meta: { permission: ['normal', 'agency'] },
  },
  {
    path: 'agency-branching',
    name: accountSetupRouteNames.AGENCY_BRANCHING,
    component: () => import('@/views/Onboarding/pages/AgencyBranching.vue'),
    meta: { permission: ['normal', 'agency'] },
  },
  {
    path: 'agency-contact',
    name: accountSetupRouteNames.AGENCY_CONTACT,
    component: () => import('@/views/Onboarding/pages/AgencyContact.vue'),
    meta: { permission: ['normal', 'agency'] },
  },
  {
    path: 'agency-contact-details',
    name: accountSetupRouteNames.AGENCY_CONTACT_DETAILS,
    component: () => import('@/views/Onboarding/pages/AgencyContactDetails.vue'),
    meta: { permission: ['normal', 'agency'] },
  },
  {
    path: 'popup-ai-branching',
    name: accountSetupRouteNames.AI,
    component: () => import('@/views/Onboarding/pages/AIFeature.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: 'branching',
    name: accountSetupRouteNames.BRANCHING,
    component: () => import('@/views/Onboarding/pages/Branching.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: 'agency/:step',
    name: accountSetupRouteNames.AGENCY,
    component: () => import('@/views/Onboarding/pages/Agency.vue'),
    meta: { permission: ['agency', 'normal'] },
    beforeEnter: (to, from, next) => {
      const locale = store.getters.getLocale;
      if (locale === 'hu') {
        return next({ name: 'templates' });
      }
      next();
    },
  },
  {
    path: 'affiliate-book-call',
    name: accountSetupRouteNames.AFFILIATE_CALLENDLY,
    component: () => import('@/views/Onboarding/pages/AffiliateCalendly.vue'),
    meta: { permission: ['normal'] },
  },
  {
    path: '*',
    name: 'onboarding_check',
    redirect: (to) => {
      const onboardingStart = { name: accountSetupRouteNames.WELCOME, params: to.params };
      const hasUnfinishedOnboarding = checkOnboardingRoute();
      if (!hasUnfinishedOnboarding) {
        return onboardingStart;
      }
      const deletedRoute = isDeletedRoute(hasUnfinishedOnboarding);
      if (deletedRoute) {
        const redirect = deletedRoute.redirect(to);
        return redirect || onboardingStart;
      }
      return hasUnfinishedOnboarding;
    },
  },
];

function hasExpiredOnboarding(registrationDate) {
  const providedDate = moment(registrationDate);

  // Calculate the difference between the current date and the provided date
  const currentDate = moment();
  const differenceInMonths = currentDate.diff(providedDate, 'months');

  // Check if the difference is greater than or equal to three
  return differenceInMonths >= 3;
}

const isAccountSetupDisabled = async () => {
  const {
    account: {
      createdAt,
      type,
      settings: { onboarding = {} },
    },
    impersonate,
  } = store.state;
  if (['agency', 'sub'].includes(type) || impersonate) {
    return true;
  }
  const { campaignCreated = false } = onboarding;

  const isOnboardingExpired = hasExpiredOnboarding(createdAt);
  if (campaignCreated || isOnboardingExpired) {
    await store.dispatch('finishOnboarding');
    return true;
  }

  return false;
};

const handleInvalidNavigation = async (to, from, next) => {
  const isDevMode = window.location.href.includes('optimonk.dev');
  const { onboardingFinished } = store.getters;
  const userId = getAccountIdFromCookie();
  const dashboardRoute = redirectToHomeScreen({ to: { fullPath: '/' }, store, params: { userId } });
  if (!isDevMode && onboardingFinished) {
    if (from.name === 'login' || (from.path || '').includes('login')) {
      return next(dashboardRoute);
    }
    return next(from);
  }

  const hasUnfinishedOnboarding = checkOnboardingRoute();
  if (hasUnfinishedOnboarding && hasUnfinishedOnboarding !== to.path) {
    return next({ path: hasUnfinishedOnboarding });
  }
  if (isDevMode && !['onboarding', 'onboarding_check'].includes(to.name)) {
    return next();
  }

  // If sub or agency or impersonate or account setup is expired (older than 3 months)
  const isAccountSetupUnavailable = await isAccountSetupDisabled();
  if (isAccountSetupUnavailable) {
    return next(dashboardRoute);
  }
  next();
};

export const accountSetupRoute = {
  path: '/:userId/onboarding',
  component: () => import('@/views/Onboarding/AccountSetup.vue'),
  name: 'onboarding',
  beforeEnter: handleInvalidNavigation,
  children,
};
